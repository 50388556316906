import { DetailViewField, DetailViewGroup, FormField, Group } from "@/interfaces"
import { CustomField, CustomFieldGroup, ShopData } from "@/interfaces/db"
import { camelCase, snakeCase } from "@/utils/with-case"
import * as _ from "lodash"
import countries from "../client/countries"
import { BROKER_ID_PLACEHOLDER_FOR_CONTACT_PERSON } from "../constants"

const FIELDS_TO_SKIP_ON_CREATE = [
  "ownerships",
  "partnerships",
  "associates",
  "client_sync_broker_ids",
  "translations",
  "portals",
  "logo_url",
]

const FIELD_TYPE_MAPPING = {
  String: "string",
  Text: "text",
  Number: "number",
  Slider: "slider",
  Dropdown: "dropdown",
  Multiselect: "multiselect",
  Tags: "multiselect",
  TagsByCategory: "multiselect",
  Boolean: "boolean",
  Date: "date",
  DateTime: "datetime",
  CountrySelect: "dropdown",
  Broker: "broker",
}

const SAVED_QUERY_FIELD_TYPE_MAPPING_OVERRIDE = {
  Number: "range",
  Dropdown: "multiselect",
  Multiselect: "multiselect",
  Boolean: "yesno",
}

const REVERT_SAVED_QUERY_FIELD_TYPE_MAPPING_OVERRIDE = {
  Number: "number",
  Dropdown: "dropdown",
  Multiselect: "multiselect",
  Boolean: "boolean",
}

function customFormFields(
  customFieldGroups: CustomFieldGroup[],
  fieldTypeMapping: Record<string, string>,
  entity?: string
): Record<string, FormField> {
  return _.flatten(customFieldGroups.map(g => g.customFields)).reduce((acc: any, field: CustomField) => {
    acc[entity == "saved_query" ? `cf_${field.name}` : camelCase(`cf_${field.name}`)] = {
      label: field.title ?? field.prettyName,
      type: fieldTypeMapping[field.fieldType],
      unit: field.unit,
      formula: field.formula,
      options: optionsSwitch(field),
      dbOptions: dbOptionsSwitch(field.fieldType),
      component: field.component,
    }
    return acc
  }, {})
}

function customFieldsTypeMapping(entity) {
  switch (entity) {
    case "saved_query":
      return _.merge(FIELD_TYPE_MAPPING, SAVED_QUERY_FIELD_TYPE_MAPPING_OVERRIDE)
    case "client":
      return _.merge(FIELD_TYPE_MAPPING, REVERT_SAVED_QUERY_FIELD_TYPE_MAPPING_OVERRIDE)
    default:
      return FIELD_TYPE_MAPPING
  }
}

function dbOptionsSwitch(fieldType: string) {
  switch (fieldType) {
    case "Broker":
      return "brokers"
    case "Tags":
      return "superGroups"
    case "TagsByCategory":
      return "superGroups"
    default:
      return null
  }
}

function optionsSwitch(field: any) {
  switch (field.fieldType) {
    case "CountrySelect":
      return countries
    case "Tags":
      return formatGroups(field)
    case "TagsByCategory":
      return formatGroupsByCategories(field)
    default:
      return field.customOptions.map(o => ({
        condition: o.condition,
        id: o.id,
        name: o.title ?? o.name,
      }))
  }
}

const COMPONENT_MAPPING = {
  string: "string-field",
  date: "date-field",
  datetime: "datetime-field",
  dropdown: "dropdown",
  broker: "dropdown",
  multiselect: "list",
  emails: "email-list",
  number: "number-field",
  slider: "slider-field",
  boolean: "boolean-field",
  findriveTipsterBanner: "findrive-tipster-banner",
  address: "address-fields",
  relationship: "relationship-field",
  children: "children-field",
  name: "name-fields",
  link: "link-field",
  phone: "phone-field",
  photo: "photo-field",
  phones: "phone-numbers-field",
  text: "text-field",
  richtext: "richtext-field",
  range: "range-field",
  yesno: "yes-no-field",
  tags: "list",
}

const containerFormField = {
  container: {
    label: "",
    component: "container",
  },
}

const resolve = <TResult, T = never>(obj: ((...params: T[]) => TResult) | TResult | undefined, ...params: T[]) =>
  _.isFunction(obj) ? obj(...params) : obj

export const resolveDetailViewField = (dvf: DetailViewField, db: ShopData) =>
  ({
    ...dvf,
    title: resolve(dvf.title, db),
    hint: resolve(dvf.hint, db),
  } as DetailViewField)

export default function (
  FormFields: Record<string, FormField>,
  detailViewGroups: DetailViewGroup[],
  isCreateView: boolean,
  $db: any,
  customFieldGroups: CustomFieldGroup[],
  additionalParams?: (field: string, value: any) => any,
  theme?: string,
  record?: any,
  submitAttempt?: boolean,
  entity?: string,
  expandFieldNamesForReadonly?: any
): Group[] {
  const formFields = _.merge(
    FormFields,
    customFormFields(customFieldGroups, customFieldsTypeMapping(entity), entity),
    containerFormField
  )
  return detailViewGroups
    .filter(group => {
      const { readBrokerIds = [], readDepartmentIds = [] } = group
      const hasRights = readBrokerIds.length > 0 || readDepartmentIds.length > 0

      const canSee = readBrokerIds.includes($db.broker.id)
      const departmentIds = $db.broker.departmentIds
      const departmentCanSee = _.intersection(readDepartmentIds, departmentIds).length > 0

      return !hasRights || canSee || departmentCanSee
    })
    .map(group => ({
      ...group,
      fields: group.detailViewFields
        .filter(f => {
          if (isCreateView && FIELDS_TO_SKIP_ON_CREATE.includes(f.fieldName)) return false

          const fieldHasRights =
            f.readBrokerIds?.length > 0 ||
            f.writeBrokerIds?.length > 0 ||
            f.readDepartmentIds?.length > 0 ||
            f.writeDepartmentIds?.length > 0

          const canSee =
            f.readBrokerIds?.includes(BROKER_ID_PLACEHOLDER_FOR_CONTACT_PERSON) ||
            f.readBrokerIds?.includes($db.broker.id)

          const departmentIds = $db.broker.departmentIds
          const departmentCanSee = _.intersection(f.readDepartmentIds, departmentIds).length > 0

          // Skip this field if field has rights and current broker isn't included in readBrokerIds or readDepartmentIds
          if (fieldHasRights && !canSee && !departmentCanSee) return false

          const fieldName = entity == "saved_query" ? f.fieldName : camelCase(f.fieldName)
          const formField = formFields[fieldName]
          if (!formField) return false
          return true
        })
        .map(f => {
          const fieldHasRights =
            f.readBrokerIds?.length > 0 ||
            f.writeBrokerIds?.length > 0 ||
            f.readDepartmentIds?.length > 0 ||
            f.writeDepartmentIds?.length > 0

          const canWrite =
            f.writeBrokerIds?.includes(BROKER_ID_PLACEHOLDER_FOR_CONTACT_PERSON) ||
            f.writeBrokerIds?.includes($db.broker.id)
          const departmentCanWrite = _.intersection(f.writeDepartmentIds, $db.broker.departmentIds).length > 0
          const fieldName = entity == "saved_query" ? f.fieldName : camelCase(f.fieldName)
          const formField = formFields[fieldName]
          const readonly =
            (typeof formField.readonly === "function"
              ? formField.readonly({ db: $db, record })
              : formField.readonly === true) ||
            (fieldHasRights && !canWrite && !departmentCanWrite)
          const extendedFieldNames = { ...formFields, ...expandFieldNamesForReadonly }

          return {
            fieldName: f.fieldName,
            hint: resolve(f.hint, $db.shopData),
            theme: theme || "default",
            readonly:
              !f.editable && !!expandFieldNamesForReadonly
                ? record[snakeCase(fieldName)] || record[extendedFieldNames[fieldName]]
                : readonly,
            color: f.color,
            mandatory: f.mandatory,
            submitAttempt: submitAttempt,
            componentName: formField.component || COMPONENT_MAPPING[(formField.type || "string").toLowerCase()],
            detailViewField: f,
            formField: {
              ...formField,
              label: resolve(f.title || formField.label, $db.shopData),
              additionalParams,
            },
          }
        }),
    }))
}

export const formatFields = (fields: Array<Partial<DetailViewField> | string>) =>
  fields.map(f => {
    const field = typeof f == "string" ? { fieldName: f } : f
    return {
      ...field,
      readBrokerIds: [],
      writeBrokerIds: [],
      readDepartmentIds: [],
      writeDepartmentIds: [],
    } as DetailViewField
  })

export const formatDefaultFields = (defaultFields, db, customFieldKey) => {
  const customFieldGroupsMapped: DetailViewGroup[] = db[customFieldKey].map(g => ({
    name: g.name,
    slot: "right" as "left" | "right",
    readBrokerIds: [],
    writeBrokerIds: [],
    readDepartmentIds: [],
    writeDepartmentIds: [],
    allowedMarketingType: null,
    allowedRsTypes: [],
    countries: [],
    clientGroupIds: [],
    detailViewFields: formatFields(g.customFields.map(cf => `cf_${cf.name}`)),
  }))

  return defaultFields
    .filter(dvg => dvg.filter?.(db) ?? true)
    .map(g => ({
      ...g,
      dealPipelineIds: typeof g.dealPipelineIds === "function" ? g.dealPipelineIds(db) : g.dealPipelineIds,
      detailViewFields: g.detailViewFields
        .filter(dvf => dvf.filter?.(db) ?? true)
        .map(f => ({
          ...f,
          dealPipelineIds: typeof f.dealPipelineIds === "function" ? f.dealPipelineIds(db) : f.dealPipelineIds,
        })),
    }))
    .concat(customFieldGroupsMapped)
}

export function formatPayload(record: any, forbiddenFields: string[]) {
  const recordWithoutForbiddenAndCustomFields = _.omitBy(
    record,
    (v, key) => key == "skipDirty" || forbiddenFields.includes(key) || _.startsWith(key, "cf_")
  )
  const recordWithOnlyCustomFields = _.pickBy(record, (v, key) => _.startsWith(key, "cf_"))
  recordWithoutForbiddenAndCustomFields.partial_custom_fields = _.mapKeys(recordWithOnlyCustomFields, (v, k) =>
    k.replace("cf_", "")
  )

  return recordWithoutForbiddenAndCustomFields
}

export function formatGroups(field) {
  return ({ db }) => {
    const superGroups = db.get("superGroups")
    return field.customOptions
      .map(co => {
        const superGroup = superGroups.find(sg => sg.id == co.groupId)
        return {
          id: co.id,
          name: superGroup ? `${superGroup.name}: ${co.name}` : co.name,
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
  }
}

export function formatGroupsByCategories(field) {
  return ({ db }) => {
    let tags: Array<{
      superGroupId: number | string
      id: number | string
      name: string
    }> = []

    switch (field.entity) {
      case "for_properties":
        tags = db.get("propertyGroups")
        break
      case "for_clients":
        tags = db.get("clientGroups")
        break
      case "for_tasks":
        tags = db.get("activityGroups")
        break
    }

    return tags
      .filter(tag => {
        let tagsSuperCategoryIds = field.tagsSuperCategoryIds || []
        return tagsSuperCategoryIds.includes(tag.superGroupId)
      })
      .map(tag => {
        return {
          id: tag.id,
          name: tag.name,
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
  }
}

export const formatRecord = (record: any) => {
  return {
    ..._.mapKeys(record, (v, k) => snakeCase(k)),
    ..._.mapKeys(record.customFields, (v, k) => `cf_${k}`),
  }
}
